@use 'sass:math';

@import 'styles/breakpoints.scss';
@import 'styles/containers.scss';
@import 'styles/typography.scss';

.wrapper {
  $local-breakpoint: 1201px; // 1025px previously
  $local-breakpoint-xl: 1400px;

  :global {
    #find-a-doctor {
      @include breakpoint(lg, max) {
        padding-top: 30px;
        margin-top: -30px;
      }
    }
  }

  // Production Syles
  :global {
    .filter-nexplanon__zipcode .ant-select-selection__rendered {
      background-color: #ffd9c9;
    }
    .ant-input.ant-select-search__field::placeholder {
      text-transform: capitalize;
    }

    .partner_290-disabled {
      // Disabled - remove "-disabled" to revert it
      .filters-disclaimer .subtext #ddc-terms-and-conditions {
        color: $brand-secondary;

        &:hover,
        &:visited,
        &:focus,
        &:active {
          color: $text-color-link-hover;
        }
      }

      .ddc-header {
        position: relative;
      }

      .filters-top-message {
        margin-top: 0;
        padding-bottom: 68px;

        @include breakpoint($local-breakpoint, max) {
          padding-top: 48px;
          padding-bottom: 48px;
        }
      }

      .filters-top-message__container {
        max-width: none;
        padding: 0;
        @include container;

        .message {
          @include breakpoint($local-breakpoint, min) {
            padding-right: 75px;
            @include h1;
          }
        }
      }

      .filter-nexplanon {
        @include breakpoint($local-breakpoint, max) {
          position: relative;
        }
      }

      .filter-nexplanon__container {
        position: static;
        max-width: none;
        @include container;

        @include breakpoint($local-breakpoint, min) {
          padding-top: 80px;
          padding-bottom: 48px;
        }

        &::after {
          @include breakpoint($local-breakpoint, min) {
            left: 50%;
            bottom: calc(161px + 30px);
          }

          @include breakpoint(xl, min) {
            bottom: calc(142px + 30px);
          }
        }

        .doctor-filter-container {
          @include breakpoint($local-breakpoint, min) {
            padding-right: 95px;
          }

          h2 {
            @include breakpoint($local-breakpoint, min) {
              @include h3;
              margin-bottom: 32px;
            }
          }

          p {
            @include breakpoint($local-breakpoint, min) {
              margin-bottom: 14px;
            }
          }
        }

        .nexplanon-filters-image {
          left: 50%;
          top: 0;
          object-fit: cover;
          object-position: top center;
          height: calc(100% - 161px);

          $breakpoints: sm, md, lg, xl, xxl;
          @each $breakpoint in $breakpoints {
            @include breakpoint($breakpoint) {
              width: math.div(map-get($container-max-widths, $breakpoint), 2) -
                $container-padding;
            }
          }

          @include breakpoint(xl, min) {
            height: calc(100% - 142px);
          }
        }
      }

      .filters-disclaimer {
        z-index: 10;
      }

      .filters-disclaimer .subtext {
        max-width: none;
        @include container;
      }
    }
  }

  // Stage Syles
  :global {
    .filter-nexplanon__zipcode .ant-select-selection__rendered {
      background-color: #ffd9c9;
    }
    .ant-input.ant-select-search__field::placeholder {
      text-transform: capitalize;
    }

    .directory-widget-disabled {
      // Disabled - remove "-disabled" to revert it
      position: relative;

      .ddc-main-container > .ddc-filters {
        position: relative;
      }

      .filter-nexplanon {
        @include breakpoint($local-breakpoint, max) {
          position: relative;
        }
      }

      // Finder Content
      .ddc-container.ddc-filters__top-section--content {
        position: static;
        max-width: none;
        @include container;

        @include breakpoint($local-breakpoint, min) {
          padding-top: 80px;
          padding-bottom: 48px;
        }

        .ddc-container.ddc-filters__top-section--titlesinput {
          @include breakpoint($local-breakpoint, min) {
            padding-right: 95px;
            width: 50%;
          }

          .ddc-filters__title > ddc-div {
            @include breakpoint($local-breakpoint, min) {
              @include h3;
              margin-bottom: 32px;
              font-family: var(--font-montserrat) !important;
            }
          }

          .ddc-filters__subtitle > ddc-div {
            @include breakpoint($local-breakpoint, min) {
              @include intro;
              margin-bottom: 14px;
            }
          }
        }

        .ddc-div-wrapper.ddc-filters__image img {
          left: 50%;
          top: 0;
          object-fit: cover;
          object-position: top center;
          height: calc(100% - 161px);

          $breakpoints: sm, md, lg, xl, xxl;
          @each $breakpoint in $breakpoints {
            @include breakpoint($breakpoint) {
              width: math.div(map-get($container-max-widths, $breakpoint), 2) -
                $container-padding;
            }
          }

          @include breakpoint(xl, min) {
            height: calc(100% - 190px);
          }

          @include breakpoint($local-breakpoint-xl, min) {
            height: calc(100% - 172px);
          }
        }

        .ddc-div-wrapper.ddc-filters__image > ddc-div::after {
          @include breakpoint($local-breakpoint, min) {
            left: 50%;
            bottom: calc(161px + 30px);
          }

          @include breakpoint(xl, min) {
            bottom: calc(190px + 30px);
          }

          @include breakpoint($local-breakpoint-xl, min) {
            bottom: calc(172px + 30px);
          }
        }
      }

      // Top Title
      .ddc-container.ddc-filters__top-header--content {
        margin-top: 0;

        @include breakpoint($local-breakpoint, min) {
          padding-bottom: 68px;
          padding-top: 92px;
        }
      }

      .ddc-filters .ddc-filters-header--text {
        @include container;

        @include breakpoint($local-breakpoint, min) {
          padding-top: 0;
          padding-bottom: 0;
          margin-top: 0;
        }

        .ddc-div-wrapper__message {
          @include breakpoint($local-breakpoint, min) {
            padding-right: 75px;
            @include h1;
          }
        }
      }

      // Disclaimer
      .ddc-filters__tac-section {
        z-index: 10;
        position: relative;
      }

      .ddc-filters__tac-section--content {
        @include container;
        padding: 40px ($container-padding - 13px) 6px ($container-padding - 5px);
      }

      .ddc-div-wrapper.ddc-filter--conditions {
        @include container;
        padding: 6px ($container-padding - 13px) 40px ($container-padding - 5px);
      }
    }
  }
}
